import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import Meta from 'vue-meta'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
Vue.config.productionTip = false
Vue.prototype.$global = global
const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})
const firebaseConfig = {
  apiKey: "AIzaSyAl4p8R-HV80CU7P4mktndMeNIR_FXfaUo",
  authDomain: "news-5-network.firebaseapp.com",
  projectId: "news-5-network",
  storageBucket: "news-5-network.appspot.com",
  messagingSenderId: "9191127675",
  appId: "1:9191127675:web:bea86f7dd875d932c180de",
  measurementId: "G-0B58VS6NH5"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')
